
#sis_integrado h1, h5 {
    font-family: 'Lato', sans-serif;
}
#integracao{
    margin-top: 70px;
    padding-top: 1rem;
}

#sis_integrado{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0D6EFD;
}

.integrado{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    color: azure;
    font-size: 14px;
}

#header_canais {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 35vh;
    font-family: 'Lato', sans-serif;
    background-color: #0d6dfd2d;
}

#header_canais span {
    color: rgb(245, 6, 6);
    font-weight: 600;
}

#header_canais p {
    text-align: center;
    font-size: 14px;
}

.chamada{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: justify;
    padding: 1rem;
    font-weight: 400;

}