.popup-wrepper {
   
    background: rgba(0, 0, 0, 0.144);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.popup {
    padding: 15px;
    width: 100%;
    max-width: 300px;
    margin: 10% auto;
    background-color: rgb(247, 247, 247);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 4px #000000;
}
.popup p {
    text-align: justify;
}
.popup-close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    color: rgb(255, 0, 0);
    z-index: 10;
}
.popup-content button{
    margin: 2px;
    text-transform: none;
}
.popup-content a{
    text-decoration: none;
    color: azure;
}



@media (max-width:1200px) {

}

@media (max-width:992px) {
  
}

@media (max-width:768px) {
    .popup {
        transform: translate(-50%, -50%);
    }
}

@media (max-width:480px) {
    .popup {
        transform: translate(-50%, -50%);
    }
}

@media (max-width: 320px) {
    .popup {
        transform: translate(-50%, -50%);
    }
}