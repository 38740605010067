footer{
 
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #0D6EFD;
    color: azure;
    text-align: center;
    padding: 0.5rem;

}
footer p {
  font-size: smaller;
}



footer a{
    text-decoration:none;
    color: azure;
    text-decoration: underline;
}

.redessociais{
    display: flex;
    align-items: center;
    justify-content: center;
}


@media (max-width:1200px) {
  

}


@media (max-width:992px) {
   

}

@media (max-width:768px) {
  
    footer{
        flex-direction: column;
        justify-content: center;
        font-size: 12px;
        padding: 1rem;
      }


}

@media (max-width:480px) {
  footer{
    flex-direction: column;
    justify-content: center;
    font-size: 12px;
    padding: 1rem;
  }


}

@media (max-width: 320px) {
   
    footer{
        flex-direction: column;
        justify-content: center;
        font-size: 12px;
        padding: 1rem;
      }

}