#header_gestao {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 35vh;
    font-family: 'Lato', sans-serif;
    background-color: #0d6dfd2d;
    margin-top: 70px;
}

.button-container{
    width: 100%;
}

#header_gestao span {
    color: rgb(245, 6, 6);
    font-weight: 600;
}

.alinhamento1 {
    text-align: justify;
}

.img_gestao img{
    width: 100%;
}

.boxgestao {
    width: 280px !important;
    height: 320px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    text-align: center;
    border: 2px solid #00000062;
    box-shadow: 4px 4px 6px #000000b9;
    border-radius: 15px;
    padding: 5px;
    padding-bottom: 15px;
}

.boxgestao h5 {
    font-family: 'Lato', sans-serif;
}

.boxgestao button {
    background-color: #0c6e03;
    color: azure;
    width: 100%;
    height: 30px;
    border: 0;
    border-radius:5px;
    cursor: pointer;

}

.modulogestao {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.green{
    color: #0e9402;
}


@media (max-width:1200px) {
  


}

@media (max-width:992px) {

    .modulogestao{
        flex-direction: column;
        justify-content: flex-start;
        
    }
    .boxgestao{
        
        width: 100%;
        height: 300px;
        margin: 2%;
        padding: 1%;
    }
}

@media (max-width:768px) {
   

    .modulogestao{
        flex-direction: column;
        justify-content: flex-start;
        
    }

    .boxgestao{
       width: 100%;
       height: 300px;
       margin: 2%;
       padding: 1%;
       
    }

    .boxgestao img{
        width: 100px;
        
     }

     .boxgestao button{
        width: 100%;
        
     }


}

@media (max-width:480px) {
    #modulos{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .modulogestao{
        flex-direction: column;
        margin: 0;
        padding: 0;
    }

    .boxgestao{
        width: 100%;
        height: 350px;
       
        
    }
    .popup{
        width: 80%;
       
    }
   

}

@media (max-width: 320px) {

    #modulos{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .modulogestao{
       
        flex-direction: column;
        margin: 0;
        padding: 0;
        
    }

    .boxgestao{
        width: 100%;
        height: 350px;
    }

    .popup{
        width: 80%;
       
    }
 

}